import FormVirtualizeAutocomplete from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { SxProps, Theme } from '@mui/material';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

type ScreenerFormVirtualizeAutocompleteProps = {
    defaultInputLabel?: string;
    inputSxProps?: SxProps;
    theme?: Theme;
    ignoreFocus?: boolean;
    placeholder?: string;
    autocompleteSxProps?: SxProps;
    alwaysShowLabel?: boolean;
    highlightOnSelection?: boolean;
    showSelectionsInLabel?: boolean;
} & FilterProps;

export const ScreenerFormVirtualizeAutocomplete: React.FC<ScreenerFormVirtualizeAutocompleteProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    defaultInputLabel,
    hide,
    component,
    parentSectionKey,
    onChangeClearHandler,
    theme,
    ignoreFocus,
    placeholder,
    autocompleteSxProps,
    getValues,
    disableDefaultValues,
    doNotSetDefaultValue,
    alwaysShowLabel = false,
    highlightOnSelection = false
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const stackLabelWithContainer = filterMetadata?.sections?.[parentSectionKey]?.stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const placeholderText = placeholder;

    if (autocompleteSxProps === undefined && filterMetadata.sxProps) {
        autocompleteSxProps = filterMetadata.sxProps;
    }

    if (highlightOnSelection) {
        autocompleteSxProps = {
            '& .MuiAutocomplete-inputRoot': {
                display: 'inline-block',
                width: 'auto',
            },
            '& .MuiInputBase-root': {
                display: 'inline-flex',
            },
            '& .MuiAutocomplete-input': {
                width: 'auto',
            }
        };
    }

    const filter = (
        <FormVirtualizeAutocomplete
            label={label}
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.Autocomplete,
                filterMetadataKey,
            })}
            options={filtersData.data[filterMetadataKey].items}
            placeholder={placeholderText}
            defaultValues={filterMetadata.default_values}
            submitHandler={submitHandler}
            defaultInputLabel={defaultInputLabel}
            onChangeClearHandler={onChangeClearHandler}
            theme={theme}
            ignoreFocus={ignoreFocus}
            alwaysShowPlaceholder={true}
            autocompleteSxProps={autocompleteSxProps}
            getValues={getValues}
            disableDefaultValues={disableDefaultValues}
            doNotSetDefaultValue={doNotSetDefaultValue}
            alwaysShowLabel={alwaysShowLabel}
            highlightOnSelection={highlightOnSelection}
            search_fields={filterMetadata.search_fields || []}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
